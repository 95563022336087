import { render, staticRenderFns } from "./ChartCompain.vue?vue&type=template&id=3b15c8cd&scoped=true&"
import script from "./ChartCompain.vue?vue&type=script&lang=js&"
export * from "./ChartCompain.vue?vue&type=script&lang=js&"
import style0 from "./ChartCompain.vue?vue&type=style&index=0&id=3b15c8cd&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b15c8cd",
  null
  
)

export default component.exports