<template>
  <b-card>
    <b-row>
      <h2>Campaign Chart</h2>

      <!-- Loader -->
      <div v-if="isLoading" class="loader">
        <div class="loading-text">Loading data...</div>
        <div class="hollow-dots-spinner">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
      </div>

      <!-- Chart -->
      <div v-else class="chart-container">
        <canvas id="campaignChart"></canvas>
      </div>
    </b-row>
  </b-card>
</template>

<script>
import {
  BTable,
  BAvatar,
  BCardText,
  BImg,
  BBadge,
  BCard,
  BRow,
  BCol,
  VBTooltip,
  BButtonGroup,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
} from "bootstrap-vue";
import Chart from "chart.js";
import wp_api from "@/libs/apis/wp_api";

export default {
  components: {
    BTable,
    BAvatar,
    VBTooltip,
    BCard,
    BImg,
    BButtonGroup,
    BBadge,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  },
  data() {
    return {
      salesData: {},
      isLoading: true, // Loader state
      chartInstance: null, // Store Chart.js instance
    };
  },
  mounted() {
    this.fetchSalesData();
  },
  methods: {
    async fetchSalesData() {
      const productId = this.$route.params.productId;
      const campaign_id = this.$route.params.id;

      if (!productId) {
        console.error("No product ID found in route params!");
        return;
      }

      try {
        const response = await wp_api.get(`/get-product-sale/${productId}/${campaign_id}`);

        if (response.data.status === "success") {
          this.salesData = response.data.data.sales;
        }
      } catch (error) {
        console.error("Error fetching sales data:", error);
      } finally {
        this.isLoading = false;
        this.$nextTick(() => {
          this.updateChart();
        });
      }
    },

    updateChart() {
      this.$nextTick(() => {
        const canvas = document.getElementById("campaignChart");

        if (!canvas) {
          console.error("Canvas not found! Chart cannot be drawn.");
          return;
        }

        console.log("Canvas found, proceeding to draw the chart.");
        const ctx = canvas.getContext("2d");
        if (this.chartInstance) {
          this.chartInstance.destroy();
        }

        if (!this.salesData || Object.keys(this.salesData).length === 0) {
          console.error("No sales data available!");
          return;
        }

        const dates = Object.keys(this.salesData);
        const sales = Object.values(this.salesData);

        if (dates.length === 0 || sales.length === 0) {
          console.error("Invalid data for chart!");
          return;
        }
        this.chartInstance = new Chart(ctx, {
          type: "line",
          data: {
            labels: dates,
            datasets: [
              {
                label: "Ventes",
                data: sales,
                borderColor: "#729af6",
                borderWidth: 3,
                fill: true,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              y: {
                beginAtZero: true,
                grid: {
                  color: "#e0e0e0", // Change the color of the y-axis grid lines
                },
              },
              x: {
                grid: {
                  color: "#e0e0e0", // Change the color of the x-axis grid lines
                },
              },
            },
          },
        });

        console.log("Chart successfully rendered!");
      });
    },
  },
};
</script>

<style scoped>
.chart-container {
  position: relative;
  width: 100%;
  height: 500px;
  /* Ensure it has a defined height */
}

canvas {
  width: 100% !important;
  height: 100% !important;
  /* Fix potential canvas collapse */
}

/* Loader Styling */
.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  color: #666;
}

.hollow-dots-spinner {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.hollow-dots-spinner .dot {
  width: 15px;
  height: 15px;
  margin: 0 7px;
  border: 3px solid #444345;
  border-radius: 50%;
  transform: scale(0);
  animation: hollow-dots-spinner-animation 1000ms ease infinite;
}

.hollow-dots-spinner .dot:nth-child(1) {
  animation-delay: 300ms;
}

.hollow-dots-spinner .dot:nth-child(2) {
  animation-delay: 600ms;
}

.hollow-dots-spinner .dot:nth-child(3) {
  animation-delay: 900ms;
}

@keyframes hollow-dots-spinner-animation {
  50% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* Loading text animation */
.loading-text {
  animation: loadingTextAnimation 1.5s infinite ease-in-out;
}

@keyframes loadingTextAnimation {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-5px);
  }

  100% {
    transform: translateY(0);
  }
}
</style>
